

















































































import Vue from "vue";

import DoceoIcon from "@/components/DoceoIcon.vue";
import DOCEO_ICONS from "@/constants/icons";

export default Vue.extend({
  name: "AboutUs",
  metaInfo() {
    return {
      link: [{ rel: "canonical", href: `${process.env.VUE_APP_BASE_URL}${this.$route.path}` }],
    };
  },
  components: {
    DoceoIcon,
  },
  data: () => ({
    DOCEO_ICONS,
  }),
  methods: {
    goToLogIn() {
      this.$router.push({ name: "Login" });
    },
  },
});
